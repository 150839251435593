import React from 'react';
import 'assets/css/Header.scss';
import Dot from "assets/images/background/bg__dot--full--alt.svg";

const Header = (): JSX.Element => {
    return (
        <div className={ "header" }>
            <div className='top_decoration_holder'>
                <div className='dot_decoration'>
                    <img src={ Dot } alt="Dot background decoration" />
                </div>
                <div className='swirl_decoration'></div>
            </div>
        </div>
    );
}

export default Header;
