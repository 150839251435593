import React from 'react';
import 'assets/css/HiddenResult.scss';
import Devices from 'types/Devices';
import { useTranslation } from 'react-i18next';

type HiddenResultProps = {
    type: Devices,
    url: string,
    title: string,
    description: string
};

const HiddenResult = ({ type, url, title, description }: HiddenResultProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={`hidden_result result_${type}`}>
            <span id={`hidden_result_${ t('form_url_title') }`}>{url}</span>
            <span id={`hidden_result_${ t('form_title_title') }`}>{title}</span>
            <span id={`hidden_result_${ t('form_description_title') }`}>{description}</span>
        </div>
    );
}

export default HiddenResult;
