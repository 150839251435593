import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "assets/css/LanguageSwitcher.scss";

type LanguageSwitcherProps = {
    currentLanguage: string,
    options: string[]
};

const LanguageSwitcher = ({ currentLanguage, options }: LanguageSwitcherProps): JSX.Element => {
    const navigate = useNavigate();
    const [value, setValue] = useState(currentLanguage);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value);
        navigate(`/${e.target.value}`, { replace: true })
    }

    return (
        <select className='language_switcher' name="language" id="language" value={ currentLanguage } onChange={ handleChange }>
            { options.map(option => (
                <option key={ option } value={ option }>{ option }</option>
            )) }
        </select>
    );
};

export default LanguageSwitcher;
