import React from "react";
import "assets/css/App.scss";
import { Navigate, Route, Routes } from "react-router-dom";
import MetatagChecker from "pages/MetatagChecker";
import Header from "components/Header";
import Footer from "components/Footer";

const App = (): JSX.Element => {
    return (
        <>
            <Header />
            <div className="app">
                <Routes>
                    <Route path="/" element={ <Navigate to="/nl" replace={ true } /> } />
                    <Route path="/:language" element={ <MetatagChecker /> } />
                </Routes>
            </div>
            <Footer />
        </>
    )
};

export default App;
