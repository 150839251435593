import React from 'react';
import Input from 'components/Inputs/Input';
import Devices from 'types/Devices';
import { DescriptionInputInfo } from 'types/InputInfo';
import { useTranslation } from 'react-i18next';

type DescriptionInputProps = {
    value: string,
    onChange: (value: string) => void,
    type: Devices
};

const DescriptionInput = ({ value, onChange, type }: DescriptionInputProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Input
            title={ t('form_description_title') }
            restrictions={ DescriptionInputInfo[type] }
            value={ value }
            onChange={ onChange }
            tooltip={ {
                title: t('tooltip_description_title'),
                description: t('tooltip_description_description')
            } }
            isTextArea={ true }
        />
    );
};

export default DescriptionInput;
