import React from 'react';
import "assets/css/Button.scss";
import Reset from "assets/images/icons/reset.png";
import { useTranslation } from 'react-i18next';

type ResetButtonProps = {
    onClick: () => void
};

const ResetButton = ({ onClick }: ResetButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <button className='button reset_button' onClick={ onClick }>
            <img src={ Reset } alt="Reset icon" />
            { t('button_reset') }
        </button>
    );
};

export default ResetButton;
