import React, { useEffect, useState } from 'react';
import 'assets/css/Input.scss';
import ProgressBar from 'components/ProgressBar';
import Tooltip from 'components/Tooltip';
import { DeviceRestrictions } from 'types/InputInfo';
import { useTranslation } from 'react-i18next';

type InputProps = {
	title: string,
	restrictions: DeviceRestrictions,
	value: string,
	onChange: (value: string) => void,
	tooltip: {
		title: string,
		description: string
	},
	isTextArea?: boolean
};

const Input = ({ title, restrictions, value, onChange, tooltip, isTextArea = false }: InputProps): JSX.Element => {
	const { t } = useTranslation();
	const [pixels, setPixels] = useState<number>(0);

	const onInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		onChange(e.currentTarget.value);
	};

	useEffect(() => {
		if (value.length !== 0) {
			const container = window.document.getElementById(`hidden_result_${title}`);
			setPixels(container?.offsetWidth || 0);
		} else {
			setPixels(0);
		}
	}, [value])

	return (
		<div className={ "input" }>
			<div className={ "container" }>
				<label htmlFor={ title }>
					<span>{ title }</span>
					<Tooltip
						title={ tooltip.title }
						content={ tooltip.description }
					/>
				</label>

				<p>{ pixels }/{ restrictions.maxPixels } { t('form_pixels') }, { value.length } { t('form_characters') }</p>
			</div>

			{ isTextArea
				? <textarea id={ title } name={ title } value={ value } onChange={ onInputChange } />
				: <input type="text" id={ title } name={ title } value={ value } onChange={ onInputChange } />
			}

			<ProgressBar
				pixels={ pixels }
				maxPixels={ restrictions.maxPixels }
				minSuccessPixels={ restrictions.minSuccess }
				maxSuccessPixels={ restrictions.maxSuccess }
			/>
		</div>
	);
}

export default Input;
