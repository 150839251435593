import "assets/css/Button.scss";
import Devices from 'types/Devices';
import TypeButton from 'components/Buttons/TypeButton';
import IconMobile from "assets/images/icons/icon_mobile.png";
import { useTranslation } from 'react-i18next';

type MobileButtonProps = {
    onClick: (device: Devices) => void
    activeDevice: Devices,
};

const MobileButton = ({ onClick, activeDevice }: MobileButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <TypeButton
            content={ t('button_example_mobile') }
            icon={ IconMobile }
            onClick={ () => onClick(Devices.Mobile) }
            active={ activeDevice === Devices.Mobile }
        />
    );
};

export default MobileButton;
