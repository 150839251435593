import React from 'react';
import 'assets/css/Result.scss';
import Devices from 'types/Devices';
import { useTranslation } from 'react-i18next';

type ResultProps = {
    type: Devices,
    url: string,
    title: string,
    description: string
};

const Result = ({ type, url, title, description }: ResultProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={ `result result_${type}` }>
            <span id="result_url">{ url.length !== 0 ? url : t('result_default_url') }</span>
            <span id="result_title">{ title.length !== 0 ? title : t('result_default_title') }</span>
            <span id="result_description">{ description.length !== 0 ? description : t('result_default_description') }</span>
        </div>
    );
}

export default Result;
