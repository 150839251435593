import React from 'react';
import 'assets/css/ProgressBar.scss';

type ProgressBarProps = {
    pixels: number,
    maxPixels: number,
    minSuccessPixels: number,
    maxSuccessPixels: number,
};

const ProgressBar = ({pixels, maxPixels, minSuccessPixels, maxSuccessPixels }: ProgressBarProps): JSX.Element => {
    const filledPercentage: number = (pixels / maxPixels) * 100;
    const success_class: string = (pixels >= minSuccessPixels && pixels <= maxSuccessPixels) ? "success" : "error";

    return (
        <div className="progress_bar">
            <div 
                className={`filled ${success_class}`}
                style={{
                    width: `${filledPercentage}%`
                }}
            ></div>
        </div>
    );
}

export default ProgressBar;
