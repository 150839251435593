import Devices from "types/Devices";

type DeviceRestrictions = {
    maxPixels: number,
    maxChars: number,
    minSuccess: number,
    maxSuccess: number,
}

type InputInfo = {
    [Devices.Desktop]: DeviceRestrictions,
    [Devices.Mobile]: DeviceRestrictions
}

const TitleInputInfo: InputInfo = {
    [Devices.Desktop]: {
        maxPixels: 575,
        maxChars: 1000,
        minSuccess: 0,
        maxSuccess: 575
    },
    [Devices.Mobile]: {
        maxPixels: 575,
        maxChars: 1000,
        minSuccess: 0,
        maxSuccess: 575
    }
}

const DescriptionInputInfo: InputInfo = {
    [Devices.Desktop]: {
        maxPixels: 920,
        maxChars: 1000,
        minSuccess: 430,
        maxSuccess: 920
    },
    [Devices.Mobile]: {
        maxPixels: 920,
        maxChars: 1000,
        minSuccess: 430,
        maxSuccess: 920
    }
}

const UrlInputInfo: InputInfo = {
    [Devices.Desktop]: {
        maxPixels: 600,
        maxChars: 1000,
        minSuccess: 0,
        maxSuccess: 600
    },
    [Devices.Mobile]: {
        maxPixels: 400,
        maxChars: 42,
        minSuccess: 0,
        maxSuccess: 400
    }
}

export type { DeviceRestrictions, InputInfo };
export { TitleInputInfo, DescriptionInputInfo, UrlInputInfo };
