import React from 'react';
import 'assets/css/Button.scss';

type TypeButtonProps = {
    content: string,
    icon: string,
    onClick: () => void,
    active: boolean
}

const TypeButton = ({ content, icon, onClick, active }: TypeButtonProps): JSX.Element => {
    return (
        <button 
            className={`button ${active ? "" : "button_non_active"}`} 
            onClick={onClick}
        >
            <img src={icon} alt={`Icon`} />
            {content}
        </button>
    );
};

export default TypeButton;
