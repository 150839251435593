import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Devices from 'types/Devices';
import Result from 'components/Result';
import { DesktopButton, MobileButton, ResetButton } from "components/Buttons";
import { TitleInput, DescriptionInput, UrlInput } from 'components/Inputs';
import HiddenResult from "components/HiddenResult";
import LanguageSwitcher from "components/LanguageSwitcher";
import i18next from 'i18next';

const MetatagChecker = (): JSX.Element => {
  const [activeDevice, setActiveDevice] = useState<Devices>(Devices.Desktop)
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");

  const [currentLanguage, setCurrentLanguage] = useState("");
  const { language } = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    i18next.changeLanguage(language, () => {
      navigate(`/${i18next.resolvedLanguage}`, { replace: true })
      setCurrentLanguage(i18next.resolvedLanguage);
    });
  }, [language]);

  const reset = () => {
    setTitle("");
    setDescription("");
    setUrl("");
  }

  return (
    <div>
      <h1>make it fly metatag checker</h1>

      <DesktopButton
        onClick={ setActiveDevice }
        activeDevice={ activeDevice }
      />
      <MobileButton
        onClick={ setActiveDevice }
        activeDevice={ activeDevice }
      />

      <LanguageSwitcher
        currentLanguage={ currentLanguage }
        options={ [
          "nl", "en", "fr"
        ] }
      />
      <ResetButton onClick={ reset } />

      <Result
        type={ activeDevice }
        url={ url }
        title={ title }
        description={ description }
      />


      <form>
        <TitleInput
          value={ title }
          onChange={ setTitle }
          type={ activeDevice }
        />

        <DescriptionInput
          value={ description }
          onChange={ setDescription }
          type={ activeDevice }
        />

        <UrlInput
          value={ url }
          onChange={ setUrl }
          type={ activeDevice }
        />
      </form>

      <HiddenResult
        type={ activeDevice }
        url={ url }
        title={ title }
        description={ description }
      />
    </div>
  )
};

export default MetatagChecker;
