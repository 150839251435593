import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationsNL from "data/nl.json";
import translationsEN from "data/en.json";
import translationsFR from "data/fr.json";

const resources = {
    nl: {
        translation: translationsNL
    },
    en: {
        translation: translationsEN
    },
    fr: {
        translation: translationsFR
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "nl",
        fallbackLng: "nl",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
