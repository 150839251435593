import "assets/css/Button.scss";
import Devices from 'types/Devices';
import TypeButton from 'components/Buttons/TypeButton';
import IconDesktop from "assets/images/icons/icon_desktop.png";
import { useTranslation } from 'react-i18next';

type DesktopButtonProps = {
    onClick: (device: Devices) => void
    activeDevice: Devices,
};

const DesktopButton = ({ onClick, activeDevice }: DesktopButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <TypeButton
            content={ t('button_example_desktop') }
            icon={ IconDesktop }
            onClick={ () => onClick(Devices.Desktop) }
            active={ activeDevice === Devices.Desktop }
        />
    );
};

export default DesktopButton;
