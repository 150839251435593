import React from 'react';
import Input from 'components/Inputs/Input';
import Devices from 'types/Devices';
import { UrlInputInfo } from 'types/InputInfo';
import { useTranslation } from 'react-i18next';

type UrlInputProps = {
    value: string,
    onChange: (value: string) => void,
    type: Devices
};

const UrlInput = ({ value, onChange, type }: UrlInputProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Input
            title={ t('form_url_title') }
            restrictions={ UrlInputInfo[type] }
            value={ value }
            onChange={ onChange }
            tooltip={ {
                title: t('tooltip_url_title'),
                description: t('tooltip_url_description')
            } }
        />
    );
};

export default UrlInput;
