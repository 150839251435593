import React from 'react';
import "assets/css/Tooltip.scss";
import QuestionMark from "assets/images/icons/question_mark.png";

type TooltipProps = {
    title: string,
    content: string
}

const Tooltip = ({ title, content }: TooltipProps): JSX.Element => {

    return (
        <div className="tooltip">
            <img src={ QuestionMark } alt="Question mark" />
            <div className={ "floater" }>
                <h2>{ title }</h2>
                <div dangerouslySetInnerHTML={ { __html: content } } />
            </div>
        </div>
    );
};

export default Tooltip;
